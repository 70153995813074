@import url(./design-system.css);

*,
*::after,
*::backdrop{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: var(--clrBodyBg);
  font-family: var(--ffMain);
  font-size: var(--fsBody);
  font-weight: 700;
}

.container{
  margin: auto;
}

.top-bar{
  width: 1440px;
  height: 156px;
  background:var(--clrDesatCyan) url('./images/bg-header-desktop.svg') no-repeat; 
  position: relative;
}

.filterbox-wrapper{
  position: absolute;
  width: 1110px;
  height: 72px;
  background: #fff;
  top:120px;
  left:50%;
  transform: translateX(-50%);

}

.homepage-wrapper{
  max-width: 1440px;
  background: var(--clrLightGrayishCyan);
  padding:75px 165px 120px 165px
}

.job-card-wrapper{
  background: #fff;
  max-width: 1110px;
  height: 152px;
  margin-bottom: 24px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}

.job-card-body{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;
}

.job-description-container{
  display: flex;
  align-items: center;
  gap:24px;
}

.job-description-body{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.job-header-container{
  display: flex;
  align-items: center;
}

.tab-new{
  background: var(--clrDesatCyan);
}

.tab-featured{
  background:var(--clrVeryDarkGrayishCyan);
}

.company-name{
  margin-right: 15px;
  color:var(--clrDesatCyan);
}

.job-position{
  color:var(--clrVeryDarkGrayishCyan);
  font-size: 22px;
  line-height: 24px;
}

.new-featured-container{
  display: flex;
  gap:8px
}

.job-description-footer{
  display: flex;
  gap:40px;
  position: relative;
}

.posted-at-info,
.contract-info,
.location-info
{
  position: relative;
  color:var(--clrDarkGrayishCyan);
}

.posted-at-info::before,
.contract-info::before{
  content:'';
  position:absolute;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background:var(--clrDarkGrayishCyan);
  right:-20px;
  top:50%;
  transform: translateY(-50%);
}

.keywords-container{
  display: flex;
  gap:16px
}

.keyword{
  cursor: pointer;
}

.filterbox-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}

.keywords-wrapper{
  display: flex;
  gap:16px
}

.keywords-tabs-wrapper{
  display: flex;
}

.tab-close{
  background: var(--clrDesatCyan);
  color:#fff;
  border-radius:0 5px 5px 0 ;
}